import gsap from "gsap";
// import any plugin! Including the Club GreenSock plugins
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);


//let sections = gsap.utils.toArray("section");



import sprite_fundido_blanco from './animaciones/fundido-blanco-2.json';
let data64_fundido_blanco = btoa(JSON.stringify(sprite_fundido_blanco));
let dataURL_fundido_blanco = `data:text/json;base64,${data64_fundido_blanco}`;

let playhead_fundido_blanco = {frame: 0},
animation_fundido_blanco = lottie.loadAnimation({
  container: document.querySelector(".fundido-rojo"),
  renderer: "svg",
  loop: true,
  autoplay: false,
  path: dataURL_fundido_blanco
});




import sprite_data_to_green from './animaciones/animate-to-green.json';
let data64_data_to_green = btoa(JSON.stringify(sprite_data_to_green));
let dataURL_data_to_green = `data:text/json;base64,${data64_data_to_green}`;

let playhead_data_to_green = {frame: 0},
animation_data_to_green = lottie.loadAnimation({
  container: document.querySelector(".fundido-verde"),
  renderer: "svg",
  loop: true,
  autoplay: false,
  path: dataURL_data_to_green
});



import sprite_data_corazon from './animaciones/data.json';
let data64_corazon = btoa(JSON.stringify(sprite_data_corazon));
let dataURL_corazon = `data:text/json;base64,${data64_corazon}`;

let playhead_corazon = {frame: 0},
animation_corazon = lottie.loadAnimation({
  container: document.querySelector(".bloque-corazon"),
  renderer: "svg",
  loop: true,
  autoplay: false,
  path: dataURL_corazon
});





window.addEventListener('load', function() {
  
  var centroX = window.innerWidth / 2;
  var centroY = window.innerHeight / 2;
  console.log('La altura de la pantalla es: ' + centroY + ' píxeles');
  var bloque_princial  = document.getElementById('contenedor-principal-total');
  var texto1  = document.querySelector('.mover-texto-1');
  var alturatexto1 = texto1.offsetHeight; 

  var texto2  = document.querySelector('.mover-texto-2');
  var alturatexto2 = texto2.offsetHeight; 
  var tx_compromiso  = document.querySelector('.txt-compromiso-com');
  var txt_convertirnos  = document.querySelector('.txt-convertirnos');



  var corason = this.document.querySelector('.bloque-corazon');
  console.log(alturatexto1);
  gsap.set(texto1, { opacity: 1, y: '-='+(centroY+alturatexto1)+'' });
  gsap.set(texto2, { opacity: 1, y: '+='+(centroY+alturatexto2)+'' });

  gsap.set(corason, { scale:0 });
  gsap.set(tx_compromiso, { opacity:0 });
  gsap.set(txt_convertirnos, { opacity:0 });  
    

  let tl = gsap.timeline({

      scrollTrigger: {
          trigger: "#contenedor-principal-total",
          pin: true,   // pin the trigger element while active
          start: "top top", // when the top of the trigger hits the top of the viewport
          end: "+=3100%", // end after scrolling 500px beyond the start
          scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
          markers: false,
          onUpdate: self => {/*console.log("Progreso: "+self.progress )*/},
        // onToggle: self => console.log("toggled. active?", self.isActive),
        //  snap: 1 / (sections.length  ),
        
        }

  });


  tl.to(texto1, { opacity: 1, y: '-100', duration: 2 });
  tl.to(texto2, { opacity: 1, y: '50', duration: 2 });



  
  tl .to(playhead_fundido_blanco, {
    frame: animation_fundido_blanco.totalFrames - 1,
    duration: 6,
    ease: "none",
    onUpdate: () => animation_fundido_blanco.goToAndStop(playhead_fundido_blanco.frame, true),
    });

  /*
  tl.fromTo(".mover-texto-2", 
      {y: 1400}, 
      {y: 330, duration: 4}
  */
  //tl.to(texto1, { opacity: 0,duration:0});
  //tl.to(texto2, { opacity: 0,duration:0});

  /*tl.to(bloque_princial, {
    'background': '#fff',
    duration: 3
  });
*/


  var textoDe = document.querySelector('.txt-de');
  var textoGente = document.querySelector('.txt-gente');


  textoDe.style.visibility = 'visible';
  gsap.set(textoDe, {opacity: 1});

  textoGente.style.visibility = 'visible';
  gsap.set(textoGente, {opacity: 1});



  var charsDe = Array.from(textoDe.textContent); 
  textoDe.textContent = '';

  var charsGente = Array.from(textoGente.textContent); 
  textoGente.textContent = '';


  var elementosDe = charsDe.map(char => {
    var spanDe = document.createElement('span');
    spanDe.textContent = char;
    spanDe.style.opacity = 0;
    textoDe.append(spanDe);
    return spanDe;
  });


  var elementoGente = charsGente.map(char => {
    var spanGente = document.createElement('span');
    spanGente.textContent = char;
    spanGente.style.opacity = 0;
    textoGente.append(spanGente);
    return spanGente;
  });



  tl.to(elementosDe, {
    opacity: 1,
    duration: 3.5,
    stagger: 0.20,
    /*ease: "power1.inOut"*/
  }, "-=2.5");

  tl.to(elementoGente, {
    opacity: 1,
    duration:1,
    stagger: 0.20,
    /*ease: "power1.inOut"*/
  });




  var textoDeletreado1 = document.querySelector('.texto-deletreado');
  var textoDeletreado2 = document.querySelector('.texto-deletreado-2');
  var textoDeletreado3 = document.querySelector('.texto-deletreado-3');
  var textoDeletreado4 = document.querySelector('.texto-deletreado-4');
  var textoDeletreado5 = document.querySelector('.texto-deletreado-5');
  var textoDeletreado6 = document.querySelector('.texto-deletreado-6');


  textoDeletreado1.style.visibility = 'visible';
  gsap.set(textoDeletreado1, {opacity: 1});

  textoDeletreado2.style.visibility = 'visible';
  gsap.set(textoDeletreado2, {opacity: 1});

  textoDeletreado3.style.visibility = 'visible';
  gsap.set(textoDeletreado3, {opacity: 1});

  textoDeletreado4.style.visibility = 'visible';
  gsap.set(textoDeletreado4, {opacity: 1});

  textoDeletreado5.style.visibility = 'visible';
  gsap.set(textoDeletreado5, {opacity: 1});

  textoDeletreado6.style.visibility = 'visible';
  gsap.set(textoDeletreado6, {opacity: 1});



  var chars = Array.from(textoDeletreado1.textContent); 
  textoDeletreado1.textContent = '';

  var chars2 = Array.from(textoDeletreado2.textContent); 
  textoDeletreado2.textContent = '';

  var chars3 = Array.from(textoDeletreado3.textContent); 
  textoDeletreado3.textContent = '';

  var chars4 = Array.from(textoDeletreado4.textContent); 
  textoDeletreado4.textContent = '';

  var chars5 = Array.from(textoDeletreado5.textContent); 
  textoDeletreado5.textContent = '';

  var chars6 = Array.from(textoDeletreado6.textContent); 
  textoDeletreado6.textContent = '';


  var elementos = chars.map(char => {
    var span = document.createElement('span');
    span.textContent = char;
    span.style.opacity = 0;
    textoDeletreado1.append(span);
    return span;
  });


  var elementos2 = chars2.map(char => {
    var span2 = document.createElement('span');
    span2.textContent = char;
    span2.style.opacity = 0;
    textoDeletreado2.append(span2);
    return span2;
  });

  
  var elementos3 = chars3.map(char => {
    var span3 = document.createElement('span');
    span3.textContent = char;
    span3.style.opacity = 0;
    textoDeletreado3.append(span3);
    return span3;
  });

  
  var elementos4 = chars4.map(char => {
    var span4 = document.createElement('span');
    span4.textContent = char;
    span4.style.opacity = 0;
    textoDeletreado4.append(span4);
    return span4;
  });

  
  var elementos5 = chars5.map(char => {
    var span5 = document.createElement('span');
    span5.textContent = char;
    span5.style.opacity = 0;
    textoDeletreado5.append(span5);
    return span5;
  });

  
  var elementos6 = chars6.map(char => {
    var span6 = document.createElement('span');
    span6.textContent = char;
    span6.style.opacity = 0;
    textoDeletreado6.append(span6);
    return span6;
  });




  tl.to(elementos, {
    opacity: 1,
    duration: 0,
    stagger: 0.4,
    ease: "power1.inOut"
  });

  tl.to(elementos, {
    'color': 'transparent',
    '-webkit-text-stroke': '1px pink',
    duration: 1
  });



  
  tl.to(elementos2, {
    opacity: 1,
    duration: 0,
    stagger: 0.4,
    ease: "power1.inOut"
  });

  tl.to(elementos2, {
    'color': 'transparent',
    '-webkit-text-stroke': '1px black',
    duration: 1
  });

  
  tl.to(elementos3, {
    opacity: 1,
    duration: 0,
    stagger:0.4,
    ease: "power1.inOut"
  });

  tl.to(elementos3, {
    'color': 'transparent',
    '-webkit-text-stroke': '1px black',
    duration: 1
  });

  
  tl.to(elementos4, {
    opacity: 1,
    duration: 0,
    stagger: 0.4,
    ease: "power1.inOut"
  });

  tl.to(elementos4, {
    'color': 'transparent',
    '-webkit-text-stroke': '1px black',
    duration: 1
  });

  
  tl.to(elementos5, {
    opacity: 1,
    duration: 0,
    stagger: 0.4,
    ease: "power1.inOut"
  });

  tl.to(elementos5, {
    'color': 'transparent',
    '-webkit-text-stroke': '1px black',
    duration: 1
  });

  
  tl.to(elementos6, {
    opacity: 1,
    duration: 0,
    stagger: 0.6,
    ease: "power1.inOut"
  });
/*
  tl.to(elementos6, {
    'color': 'transparent',
    '-webkit-text-stroke': '1px black',
    duration: 0.5
  });
*/


  tl .to(playhead_data_to_green, {
    frame: animation_data_to_green.totalFrames - 1,
    duration: 5,
    ease: "none",
    onUpdate: () => animation_data_to_green.goToAndStop(playhead_data_to_green.frame, true),
  },"sametime1");

  
    

  tl.to(tx_compromiso, {
    opacity: 1,
    duration: 5,
  },"sametime1");




    

    tl.to(corason, {
      scale: 1.5,
      duration: 3,
    });

    tl .to(playhead_corazon, {
      frame: animation_corazon.totalFrames - 1,
      duration: 3,
      ease: "none",
      onUpdate: () => animation_corazon.goToAndStop(playhead_corazon.frame, true),
      },"sametime2");


      tl.to(tx_compromiso, {
        'color': 'transparent',
        '-webkit-text-stroke': '1px #fff',
        duration: 2
      },"sametime2");
    
      


    

      tl.to(txt_convertirnos, {
        opacity: 1,
        duration: 5,
    });
    


      

});